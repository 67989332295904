import { Buffer } from 'buffer'

import axios from 'axios'
import { Field, Form, Formik, getIn } from 'formik'
import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import AnimateHeight from 'react-animate-height'
import { createPortal } from 'react-dom'
import { Options } from 'react-select'


import { SearchForm, SearchFormData, OptionType, Suburb } from './Search.types'
import Button from '../Button/Button'
import Check from '../Check/Check'
import { MoreSearchOptionsIcon, RefreshIcon, SearchIcon, SharpIcon } from '../icons'
import { MiniSearch } from '../MiniSearch/MiniSearch'
import NeighbouringSuburbs from '../NeighbouringSuburbs/NeighbouringSuburbs'
import { parseOptions } from '../selects/FormSelect/FormSelect'
import SearchFormSelect from '../selects/SearchFormSelect'
import { groupBy } from '../utils/groupBy'
import { uniqueArray } from '../utils/uniqueArray'
import UrlParser from '../utils/UrlParser'
import useCustomCompareMemo from '../utils/useCustomCompareMemo'


const api = (url: string) => (window.request.search_locations ? `/v2/ajax${url}` : `/ajax${url}`)

const search_secondary = document.getElementById('search-secondary')

type Categories = {
  'For Sale': Map<string, number>
  'To Let': Map<string, number>
}

const categories: Categories = {
  'For Sale': new Map<string, number>(),
  'To Let': new Map<string, number>()
}

const CATEGORIES = [
  'Residential',
  'Residential Development',
  'Residential Estate',
  'Residential Vacant Land',
  'Commercial',
  'Commercial and Industrial Development',
  'Commercial Estate',
  'Commercial & Industrial Vacant Land',
  'Industrial',
  'Mixed Use',
  'Retail',
  'Agricultural',
  'Farms & Small Holdings',
  'Tenders',
  'Auction',
  'Bank Repossessed',
  'Bank Assisted',
  'Auctions',
  'Holiday Accommodation',
  'Student Accommodation'
]

const buy_rent_list = [ 'For Sale', 'To Let' ]

let buySelectOptions: OptionType<number>[] = []

let countTimer: ReturnType<typeof setTimeout>

const csrftoken = Cookies.get('csrftoken')

const initializeSearch = () => axios.post(api('/setup-search/'), {}, { headers: { 'X-CSRFToken': csrftoken } }).then(resp => {
  window.searchData = resp.data
  buySelectOptions = []
  Object.keys(window.searchData).sort((a, b) => buy_rent_list.indexOf(b) - buy_rent_list.indexOf(a)).forEach(key => {
    let c_key: string
    if ([
      'For Sale'
    ].includes(key)) {
      c_key = 'For Sale'
      buySelectOptions.push({ value: c_key, label: 'Buy' })
    }
    if ([
      'To Let'
    ].includes(key)) {
      c_key = 'To Let'
      buySelectOptions.push({ value: c_key, label: 'Rent' })
    }
    buySelectOptions = uniqueArray(buySelectOptions, 'value')
    if (window.request.search_locations) {
      getIn(window.searchData, key).forEach(listing_type => {
        categories[c_key as keyof Categories].set(listing_type, 1)
      })
    } else {
      Object.keys(getIn(window.searchData, key)).forEach(listing_type => {
        categories[c_key as keyof Categories].set(listing_type, 1)
      })
    }
  })
  return window.searchData
}).catch(e => console.error(e))

let default_buy_rent = 'For Sale'
if ([ 'For Sale', 'Buy' ].includes(window.request.search_defaults?.buy_rent)) {
  default_buy_rent = 'For Sale'
} else if ([ 'To Let', 'Rent' ].includes(window.request.search_defaults?.buy_rent)) {
  default_buy_rent = 'To Let'
}

const initialForm: SearchForm = {
  buy_rent: default_buy_rent,
  category: window.request.search_defaults ? window.request.search_defaults.category : 'Residential',
  locations: window.request.previous_search?.locations as string[] || [],
  property_types: [],
  additional_locations: [],
  min_beds: undefined,
  web_ref_search: '',
  min_price: undefined,
  max_price: undefined,
  size_from: undefined,
  size_to: undefined,
  on_show: false,
  flatlet: false,
  pets_allowed: false,
  furnished: false
}

if (initialForm.category?.includes('&amp;')) {
  initialForm.category = initialForm.category.replace('&amp;', '&')
}

const urlParser = new UrlParser(`${window.location.pathname}${window.location.search}`)

const prefillInitialForm = (form: SearchForm) => {
  let prefilledForm = { ...form }
  if (getIn(window, 'request.search_params')) {
    const decodedRequestBodyString = Buffer.from(window.request.search_params, 'base64')
    const requestBodyObject = JSON.parse(decodedRequestBodyString.toString())
    prefilledForm = requestBodyObject
  }
  const queryValues = urlParser?.queryData
  const { sub, listing_type, area, suburb, property_type } = urlParser?.parsedValues?.groups || {
    sub: null,
    listing_type: null,
    area: null,
    suburb: null,
    property_type: null
  }

  if (listing_type === 'for-sale') {
    prefilledForm.buy_rent = 'For Sale'
  }

  if (listing_type === 'to-let' || listing_type === 'letting') {
    prefilledForm.buy_rent = 'To Let'
  }

  if (prefilledForm.category?.includes('&amp;')) {
    prefilledForm.category = prefilledForm.category.replace('&amp;', '&')
  }

  let parsedCategoryId
  let parsedCategory = prefilledForm.category
  switch (sub) {
    case 'residential':
      parsedCategory = 'Residential'
      if (property_type === 'vacant-land' && area === 'all' && suburb === 'all') {
        parsedCategory = 'Residential Vacant Land'
      }
      break
    case 'commercial':
      parsedCategory = 'Commercial'
      if (property_type === 'vacant-land' && area === 'all' && suburb === 'all') {
        parsedCategory = 'Commercial & Industrial Vacant Land'
      }
      break
    case 'industrial':
      parsedCategory = 'Industrial'
      break
    case 'retail':
      parsedCategory = 'Retail'
      break
    case 'agricultural':
      parsedCategory = 'Agricultural'
      break
    case 'mixed-use':
      parsedCategory = 'Mixed Use'
      break
    case 'farms-and-small-holdings':
      parsedCategory = 'Farms & Small Holdings'
      break
    case 'new-development':
      if (listing_type === 'residential') {
        parsedCategory = 'Residential Development'
      } else {
        parsedCategory = 'Commercial & Industrial New Development'
      }
      break
    case 'estate':
      if (listing_type === 'residential') {
        parsedCategory = 'Residential Estate'
      } else {
        parsedCategory = 'Commercial & Industrial Estate'
      }
      break
    case 'holiday':
      prefilledForm.buy_rent = 'To Let'
      parsedCategory = 'Holiday Accommodation'
      break
    case 'auction':
    case 'auctions':
      prefilledForm.buy_rent = 'For Sale'
      parsedCategory = 'Auction'
      break
    case 'residential-vacant-land':
      parsedCategory = 'Residential Vacant Land'
      break
    case 'commercial-vacant-land':
      parsedCategory = 'Commercial & Industrial Vacant Land'
      break
    default:
      parsedCategory = prefilledForm.category
      if (parsedCategory === 'Auctions') {
        parsedCategory = 'Auction'
      }
  }
  const selectedCategories = categories[prefilledForm.buy_rent as keyof Categories]

  if (selectedCategories) {
    if (selectedCategories.has(parsedCategory)) {
      parsedCategoryId = parsedCategory
    } else {
      parsedCategoryId = selectedCategories.keys().next().value
    }
  }

  // const locationsMeta = queryValues.locations_meta
  const minPrice = queryValues.min_price
  const maxPrice = queryValues.max_price
  const sizeFrom = queryValues.size_from
  const sizeTo = queryValues.size_to
  const minBeds = queryValues.min_beds
  const onShow = queryValues.on_show
  const flatlet = queryValues.flatlet
  const allowPets = queryValues.pets_allowed

  const selectedAreas = getIn(window.request.previous_search, 'area__in__or', []) || []
  const selectedSuburbs = getIn(window.request.previous_search, 'location__in__or', []) || []
  const selectedPropertyTypes = getIn(window.request.previous_search, 'property_types', []) || []
  if (selectedAreas.length) {
    prefilledForm.locations = prefilledForm.locations ? uniqueArray([
      ...prefilledForm.locations,
      ...selectedAreas.map(a => `a_${a}`)
    ]) : uniqueArray([ ...selectedAreas.map(a => `a_${a}`) ])
  }
  if (selectedSuburbs.length) {
    prefilledForm.locations = prefilledForm.locations ? uniqueArray([
      ...prefilledForm.locations,
      ...selectedSuburbs.map(a => `s_${a}`)
    ]) : uniqueArray([ ...selectedSuburbs.map(a => `s_${a}`) ])
  }
  if (selectedPropertyTypes.length) {
    // eslint-disable-next-line max-len
    prefilledForm.property_types = prefilledForm.property_types ? uniqueArray([
      ...prefilledForm.property_types,
      ...selectedPropertyTypes
    ]) : uniqueArray([ ...selectedPropertyTypes ])
  }
  prefilledForm.category = parsedCategoryId

  prefilledForm.min_price = minPrice ? Number(decodeURIComponent(minPrice)) : undefined

  prefilledForm.max_price = maxPrice ? Number(decodeURIComponent(maxPrice)) : undefined

  prefilledForm.size_from = sizeFrom ? Number(decodeURIComponent(sizeFrom)) : undefined

  prefilledForm.size_to = sizeTo ? Number(decodeURIComponent(sizeTo)) : undefined

  prefilledForm.min_beds = minBeds ? String(Number(decodeURIComponent(minBeds))) : undefined

  prefilledForm.on_show = Boolean(onShow)

  prefilledForm.flatlet = Boolean(flatlet)

  prefilledForm.pets_allowed = Boolean(allowPets)
  return prefilledForm
}

const getPostData = (form: SearchForm, {
  includeAdvanced = true
}): SearchFormData => {
  const { buy_rent, category, locations, property_types, additional_locations, ...other } = form
  const data: SearchFormData = {
    model__in: [],
    listing_type: '',
    loc: [],
    property_type__in: property_types,
    zoning: ''
  }
  switch (category) {
    case 'Residential':
      data.listing_type = buy_rent
      data.model__in = [ 'residential' ]
      break
    case 'Residential Vacant Land':
      data.listing_type = buy_rent
      data.model__in = [ 'residential' ]
      data.property_type__in = [ 'Vacant Land' ]
      break
    case 'Commercial':
      data.listing_type = buy_rent
      data.model__in = [ 'commercial' ]
      data.zoning = 'Commercial'
      break
    case 'Commercial & Industrial Vacant Land':
      data.listing_type = buy_rent
      data.model__in = [ 'commercial' ]
      data.property_type__in = [ 'Vacant Land' ]
      break
    case 'Retail':
      data.listing_type = buy_rent
      data.model__in = [ 'commercial' ]
      data.zoning = 'Retail'
      break
    case 'Industrial':
      data.listing_type = buy_rent
      data.model__in = [ 'commercial' ]
      data.zoning = 'Industrial'
      break
    case 'Mixed Use':
      data.listing_type = buy_rent
      data.model__in = [ 'commercial' ]
      data.zoning = 'Mixed Use'
      break
    case 'Agricultural':
      data.listing_type = buy_rent
      data.model__in = [ 'commercial' ]
      data.zoning = 'Agricultural'
      break
    case 'Farms & Small Holdings':
      data.model__in = [ 'residential', 'commercial' ]
      data.listing_type = buy_rent
      data.property_type__in = [ 'Farm', 'Small Holding' ]
      break
    case 'Commercial & Industrial Development':
      data.model__in = [ 'project' ]
      data.listing_type = 'Commercial Development'
      break
    case 'Commercial Estate':
      data.model__in = [ 'project' ]
      data.listing_type = 'Commercial Estate'
      break
    case 'Residential Development':
      data.model__in = [ 'project' ]
      data.listing_type = 'Residential Development'
      break
    case 'Residential Estate':
      data.model__in = [ 'project' ]
      data.listing_type = 'Residential Estate'
      break
    case 'Holiday Accommodation':
      data.model__in = [ 'holiday' ]
      break
    case 'Auction':
    case 'Auctions':
      data.listing_type = 'For Sale'
      data.auction = true
      break
    case 'Bank Repossessed':
      data.listing_type = 'For Sale'
      data.bank_repossessed = true
      break
    case 'Bank Assisted':
      data.listing_type = 'For Sale'
      data.distressed = true
      break
    case 'Student Accommodation':
      data.listing_type = 'To Let'
      data.model__in = [ 'residential' ]
      data.student_accommodation = true
      break
    default:
      break
  }

  if (locations) {
    const location_data = additional_locations ? (
      [ ...locations, ...additional_locations ]
    ) : locations
    data.loc = location_data
  }

  if (other.min_price === '') {
    delete other.min_price
  }

  if (other.max_price === '') {
    delete other.max_price
  }
  if (other.on_show === false) {
    delete other.on_show
  }
  if (other.flatlet === false) {
    delete other.flatlet
  }
  if (other.pets_allowed === false) {
    delete other.pets_allowed
  }
  if (other.furnished === false) {
    delete other.furnished
  }

  return includeAdvanced ? { ...data, ...other } : data
}

const getProcessedValues = (form: SearchForm): SearchForm => {
  if (form.min_price === '') {
    delete form.min_price
  }
  if (form.max_price === '') {
    delete form.max_price
  }
  if (form.on_show === false) {
    delete form.on_show
  }
  if (form.flatlet === false) {
    delete form.flatlet
  }
  if (form.pets_allowed === false) {
    delete form.pets_allowed
  }
  if (form.furnished === false) {
    delete form.furnished
  }
  return form
}

const minBedsOptions: OptionType<number>[] = [
  { value: '0', label: 'Any' },
  { value: '1', label: '1+' },
  { value: '2', label: '2+' },
  { value: '3', label: '3+' },
  { value: '4', label: '4+' },
  { value: '5', label: '5+' }
]
/*
display_min_price
display_max_price
display_size_from
display_size_to
display_beds
on_show
flatlet
pets_allowed
furnished
*/
const categoryTypeOptions = {
  'For Sale': {
    Residential: {
      display_property_types: true,
      display_min_price: true,
      display_max_price: true,
      display_beds: true,
      on_show: true,
      flatlet: true,
      pets_allowed: true
    },
    Commercial: {
      display_property_types: true,
      display_min_price: true,
      display_max_price: true,
      on_show: true
    },
    'Residential Vacant Land': {
      display_min_price: true,
      display_max_price: true,
      on_show: true
    },
    'Commercial & Industrial Vacant Land': {
      display_min_price: true,
      display_max_price: true,
      on_show: true
    },
    'Farms & Small Holdings': {
      display_min_price: true,
      display_max_price: true,
      on_show: true
    },
    Industrial: {
      display_property_types: true,
      display_min_price: true,
      display_max_price: true,
      on_show: true
    },
    Retail: {
      display_property_types: true,
      display_min_price: true,
      display_max_price: true,
      on_show: true
    },
    'Mixed Use': {
      display_property_types: true,
      display_min_price: true,
      display_max_price: true,
      on_show: true
    },
    Auction: {
      display_property_types: true,
      on_show: true
    },
    Agricultural: {
      display_property_types: true,
      display_min_price: true,
      display_max_price: true,
      on_show: true
    },
    'Residential Estate': {
      display_min_price: true,
      on_show: true
    },
    'Residential Development': {
      display_min_price: true,
      on_show: true
    },
    'Commercial Estate': {
      display_min_price: true,
      on_show: true
    },
    'Commercial & Industrial Development': {
      display_min_price: true,
      on_show: true
    }
  },
  'To Let': {
    Residential: {
      display_property_types: true,
      display_min_price: true,
      display_max_price: true,
      display_beds: true,
      furnished: true,
      on_show: true,
      flatlet: true,
      pets_allowed: true
    },
    Commercial: {
      display_property_types: true,
      display_size_from: true,
      display_size_to: true,
      on_show: true
    },
    'Residential Vacant Land': {
      display_size_from: true,
      display_size_to: true,
      on_show: true
    },
    'Commercial & Industrial Vacant Land': {
      display_size_from: true,
      display_size_to: true,
      on_show: true
    },
    'Farms & Small Holdings': {
      display_min_price: true,
      display_max_price: true,
      on_show: true
    },
    Industrial: {
      display_property_types: true,
      display_size_from: true,
      display_size_to: true,
      on_show: true
    },
    Retail: {
      display_property_types: true,
      display_size_from: true,
      display_size_to: true,
      on_show: true
    },
    'Mixed Use': {
      display_property_types: true,
      display_size_from: true,
      display_size_to: true,
      on_show: true
    },
    Agricultural: {
      display_property_types: true,
      display_size_from: true,
      display_size_to: true,
      on_show: true
    },
    'Holiday Accommodation': {
      display_property_types: true,
      display_min_price: true,
      display_max_price: true,
      display_beds: true,
      on_show: true
    }
  }
}
// eslint-disable-next-line import/no-named-as-default-member
let source = axios.CancelToken.source()

type MiniSearchPortalProps = {
  propertyCount: number
  selectedPropertyTypes: OptionType<string>[],
  container: HTMLElement
}

const MiniSearchPortal = ({
  propertyCount,
  selectedPropertyTypes,
  container
}: MiniSearchPortalProps) => createPortal(
  <MiniSearch
    propertyCount={propertyCount}
    selectedPropertyTypes={selectedPropertyTypes}
  />,
  container as HTMLElement
) as React.ReactNode


const webRefSearchSubmit = (values: SearchForm) => {
  const value = values.web_ref_search
  if (value) {
    const form = document.createElement('form')
    form.setAttribute('action', '/results/web-ref/')
    form.setAttribute('method', 'GET')
    const input = document.createElement('input')
    input.setAttribute('name', 'q')
    input.setAttribute('value', value)
    form.appendChild(input)
    document.body.appendChild(form)
    form.submit()
  }
}

const searchSubmit = (values: SearchForm) => {
  const encodedValues = Buffer.from(JSON.stringify(getProcessedValues(values))).toString('base64')
  const encodedString = Buffer.from(JSON.stringify(getPostData(values, { includeAdvanced: true }))).toString('base64')
  const form = document.createElement('form')
  form.setAttribute('method', 'POST')
  form.setAttribute('action', '/results/setup-search-results/')
  const input = document.createElement('input')
  const valuesInput = document.createElement('input')
  const token = document.createElement('input')
  valuesInput.setAttribute('name', 'search_values')
  valuesInput.setAttribute('value', encodedValues)
  input.setAttribute('name', 'search_params')
  input.setAttribute('value', encodedString)
  token.setAttribute('name', 'csrfmiddlewaretoken')
  token.setAttribute('value', csrftoken || '')
  form.appendChild(input)
  form.appendChild(valuesInput)
  form.appendChild(token)
  document.body.appendChild(form)
  form.submit()
}

const fetchPropertyTypes = (data: SearchForm) => axios.post(api('/property-types/'), data, { headers: { 'X-CSRFToken': csrftoken } })

let propertyTimer: ReturnType<typeof setTimeout>

export const Search = (): React.ReactNode => {
  const [ initialized, setInitialized ] = useState(false)
  const [ webRefSearchActive, _setWebRefSearchActive ] = useState(false)
  const [ initVals, setInitVals ] = useState(initialForm)
  useEffect(() => {
    initializeSearch().then(() => {
      setInitialized(true)
      setInitVals(prefillInitialForm(initialForm))
    })
  }, [])
  return (
    <div className={'search-container'}>
      <svg aria-hidden={true} style={{ position: 'absolute', width: 0, height: 0, overflow: 'hidden' }} viewBox="0 0 17 13" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <symbol id="icon16-Check-Small" viewBox="0 0 17 13">
            <path d="M6.64085 7.69738C6.58412 7.75378 6.49177 7.75378 6.43504 7.69738L2.61285 3.89996L0 6.49852L3.82144 10.2987L6.53836 13L17 2.59708L14.3849 0L6.64085 7.69738Z" />
          </symbol>
        </defs>
      </svg>
      <Formik
        initialValues={initVals}
        enableReinitialize
        onSubmit={webRefSearchActive ? webRefSearchSubmit : searchSubmit}
      >{props => {
          const { values } = props
          const [ advanced, setAdvanced ] = useState(false)

          const [ locationsResponse, setLocationsResponse ] = useState<Suburb[]>([])

          const [ flatLocations, setFlatLocations ] = useState<(OptionType<string> | Suburb)[]>([])

          const [ areaOptions, setAreaOptions ] = useState<OptionType<string>[]>([])

          const [ selectedPropertyTypes, setSelectedPropertyTypes ] = useState([])

          const [ propertyCount, setPropertyCount ] = useState<number>(0)
          const {
            buy_rent,
            category,
            locations,
            web_ref_search
          } = values
          const [ typeOptions, setTypeOptions ] = useState(getIn(categoryTypeOptions, `${buy_rent}.${category}`))

          let categoriesOptions: OptionType<string>[] = []
          categories[buy_rent as keyof Categories]?.forEach((
            key,
            value
          ) => categoriesOptions.push({ value: value, label: value }))
          categoriesOptions = categoriesOptions
            .sort((a, b) => CATEGORIES.indexOf(a.value) - CATEGORIES.indexOf(b.value))
          useEffect(() => {
            const groupByCountry = groupBy(locationsResponse, 'country')

            const areaData: OptionType<string>[] = []
            Object.keys(groupByCountry).sort((a, b) => {
              if (a < b) {
                return -1
              }
              if (a > b) {
                return 1
              }
              return 0
            }).map(country => {
              const groupByProvince = groupBy(groupByCountry[country], 'province')
              const provinceOptions = Object.keys(groupByProvince).sort().map(province => {
                const suburbs = groupByProvince[province].map((suburb: Suburb) => (
                  {
                    label: `${suburb.area}, ${suburb.suburb}`,
                    value: suburb.id,
                    area: suburb.area_id,
                    province
                  }
                ))
                suburbs.sort((a, b) => {
                  let A: string = a.label
                  let B: string = b.label
                  if (A.includes('All Suburbs')) {
                    A = `${a.label.split(', ')[0]}`
                  }
                  if (B.includes('All Suburbs')) {
                    B = `${b.label.split(', ')[0]}`
                  }
                  if (A < B) {
                    return -1
                  }
                  if (A > B) {
                    return 1
                  }
                  return 0
                })
                return {
                  label: province,
                  options: suburbs
                }
              })
              areaData.push({
                label: country,
                options: provinceOptions,
                country
              })
            })
            setAreaOptions(areaData)
          }, [ useCustomCompareMemo(locationsResponse) ])

          let pricesLabels
          if (category === 'Holiday Accommodation') {
            pricesLabels = [ 'Rates From (Per Night)', 'Rates To (Per Night)' ]
          } else if (buy_rent === 'For Sale') {
            pricesLabels = [ 'Price From', 'Price To' ]
          } else {
            pricesLabels = [ 'Rental From', 'Rental To' ]
          }

          const fetchLocations = async (inputValue): Promise<Options<OptionType<string>>> => {
            if (!inputValue) {
              return []
            }
            try {
              const results = await axios.post(api('/location-search/'), { ...values, term: inputValue }, { headers: { 'X-CSRFToken': csrftoken } })
              return results.data.map(result => ({
                value: `{"id": ${result.id}, "type": "${result.type}"}`,
                id: result.id,
                name: result.label,
                label: result.name,
                type: result.type
              })) as unknown as Options<OptionType<string>>
            } catch (e) {
              console.error(e)
            }
            return []
          }

          const findLocations = () => {
            if (window.request.search_locations) {
              return
            }
            if (window.searchData && category && buy_rent) {
              const selectData = getIn(window.searchData, `${buy_rent}.${category}`)
              const selectedLocations = getIn(selectData, 'locations') as Suburb[]
              if (selectedLocations) {
                const avialableLocations = Object.values(selectedLocations)
                setLocationsResponse(avialableLocations)
                setFlatLocations(parseOptions(avialableLocations))
              }
            }
          }

          const findPropertyTypes = () => {
            if (window.request.search_locations) {
              if (!category || !buy_rent) { return }
              clearTimeout(propertyTimer)
              propertyTimer = setTimeout(() => {
                fetchPropertyTypes(values).then(response => response.data).then(propertyTypes => {
                  setSelectedPropertyTypes(propertyTypes.map(pt => ({ value: pt, label: pt })))
                })
              }, 300)
              return
            }
            if (window.searchData && category && buy_rent) {
              const selectData = getIn(window.searchData, `${buy_rent}.${category}`)
              let propertyTypes = []
              if (!locations || locations.length === 0) {
                propertyTypes = getIn(selectData, 'property_types')?.map((pt: string) => ({ value: pt, label: pt })) || []
                setSelectedPropertyTypes(propertyTypes)
              } else {
                locations.forEach(loc => {
                  const location_types = getIn(selectData, `locations.${loc}.property_types`) || []
                  propertyTypes = [
                    ...propertyTypes,
                    ...location_types.map((pt: string) => ({ value: pt, label: pt }))
                  ]
                })
                setSelectedPropertyTypes(propertyTypes)
              }
              propertyTypes = uniqueArray(propertyTypes, 'value')
              setSelectedPropertyTypes(propertyTypes)
            }
          }

          const resetAdvanced = (additionalProps: SearchForm) => {
            setTimeout(() => {
              props.setValues(prevState => (
                {
                  ...prevState,
                  ...additionalProps,
                  flatlet: undefined,
                  allow_pets: undefined,
                  on_show: undefined,
                  furnished: undefined,
                  min_price: '',
                  max_price: '',
                  web_ref_search: '',
                  additional_locations: [],
                  min_beds: undefined,
                  size_from: '',
                  size_to: '',
                  pets_allowed: undefined
                }
              ))
            })
          }

          const setWebRefSearchActive = () => {
            if (advanced) {
              setAdvanced(false)
            }
            _setWebRefSearchActive(!webRefSearchActive)
          }

          useEffect(() => {
            let cat = categoriesOptions.length ? categoriesOptions[0].value : 'Residential'
            if (category && categoriesOptions.find(c => c.value === category)) {
              cat = category
            }
            if (props.touched.buy_rent) {
              resetAdvanced({ category: cat })
            }
          }, [ buy_rent, category, initialized ])


          useEffect(() => {
            findLocations()
            setTypeOptions(getIn(categoryTypeOptions, `${buy_rent}.${category}`))
            if (props.touched.buy_rent || props.touched.category) {
              resetAdvanced({ locations })
            }
          // @ts-ignore
          }, [ buy_rent, category, useCustomCompareMemo(flatLocations), initialized ])


          useEffect(() => {
            findPropertyTypes()
          }, [ buy_rent, category, useCustomCompareMemo(locations), initialized ])

          useEffect(() => {
            if (!(category)) { return }

            if (props.touched.buy_rent || props.touched.category || props.touched.locations) {
              resetAdvanced({ property_types: [] })
            }
          }, [ buy_rent, category, useCustomCompareMemo(locations), initialized ])

          useEffect(() => {
            if (!category || !buy_rent) { return }
            if (countTimer) {
              source.cancel()
              // eslint-disable-next-line import/no-named-as-default-member
              source = axios.CancelToken.source()
            }
            clearTimeout(countTimer)
            countTimer = setTimeout(() => {
              axios.post(api('/property-count/'), getPostData(props.values, {}), { headers: { 'X-CSRFToken': csrftoken }, cancelToken: source.token }).then(resp => setPropertyCount(resp.data.count)).catch(e => console.error(e))
            }, 300)
          }, [
            useCustomCompareMemo(values)
          ])
          return (
            <Form>

              {!webRefSearchActive && <div className="advanced-search-top">
                <div>
                  <SearchFormSelect
                    field={'buy_rent'}
                    formikProps={props}
                    hideSelectedOptions={false}
                    options={buySelectOptions}
                  />
                </div>

                <div>
                  <SearchFormSelect
                    field={'category'}
                    formikProps={props}
                    hideSelectedOptions={false}
                    options={categoriesOptions}
                  />
                </div>

                <div>
                  <SearchFormSelect
                    field={'locations'}
                    formikProps={props}
                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                    placeholder="Type Area or Suburb Name"
                    options={areaOptions}
                    loadOptions={window.request.search_locations ? fetchLocations : null}
                    noOptionsMessage={({ inputValue }) => (inputValue ? `Sorry, we couldn't find any locations matching "${inputValue}"` : null)}
                    isClearable={false}
                    isMulti
                  />
                </div>
              </div>}

              {(webRefSearchActive && !getIn(window, 'request.is_mobile')) ? (
                <div className="advanced-search-webref-fields">
                  <Field
                    type="text"
                    name="web_ref_search"
                    placeholder="Web Reference Number e.g. RL123"
                    className="input-field"
                  />

                  <Button
                    variant={web_ref_search ? 'primary' : 'disabled'}
                    onClick={() => props.submitForm()}>
                    <SearchIcon />
                  SEARCH
                  </Button>
                </div>
              ) : null}

              {!webRefSearchActive && (!getIn(window, 'request.is_mobile') || getIn(window, 'request.path') === '/property-search/') ? (
                <AnimateHeight
                  duration={300}
                  height={advanced ? 'auto' : 0}
                >
                  <div className={`advanced-search-content${advanced ? ' expanded' : ''}`}>
                    {!window.request.search_locations ? (
                      <NeighbouringSuburbs formikProps={props}
                        flatLocations={flatLocations} />
                    ) : null}

                    <div className="advanced-search-fields">
                      {typeOptions?.display_property_types ? (
                        <div className="advanced-search-property-types">
                          <span className="input-label">Property Types</span>
                          <SearchFormSelect
                            field={'property_types'}
                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                            formikProps={props}
                            placeholder="Any"
                            options={selectedPropertyTypes}
                            isMulti
                          />
                        </div>
                      ) : null}

                      <div className="advanced-search-filters">
                        {typeOptions?.display_min_price ? (
                          <div className="flex-column">
                            <span className="input-label">{pricesLabels[0]}</span>
                            <Field
                              type={'number'}
                              name={'min_price'}
                              placeholder={'e.g. 500000'}
                              className={'input-field'}
                            />
                          </div>
                        ) : null}

                        {typeOptions?.display_max_price ? (
                          <div className="flex-column">
                            <span className="input-label">{pricesLabels[1]}</span>
                            <Field
                              type={'number'}
                              name={'max_price'}
                              placeholder={'e.g. 1000000'}
                              className={'input-field'}
                            />
                          </div>
                        ) : null}

                        {typeOptions?.display_size_from ? (
                          <div className="flex-column">
                            <span className="input-label">Size From (m²)</span>
                            <Field
                              type={'number'}
                              name={'size_from'}
                              placeholder={'e.g. 500'}
                              className={'input-field'}
                            />
                          </div>
                        ) : null}

                        {typeOptions?.display_size_to ? (
                          <div className="flex-column">
                            <span className="input-label">Size To (m²)</span>
                            <Field
                              type={'number'}
                              name={'size_to'}
                              placeholder={'e.g. 1000'}
                              className={'input-field'}
                            />
                          </div>
                        ) : null}


                        {typeOptions?.display_beds ? (
                          <div className="flex-column">
                            <span className="input-label">{category === 'Holiday Accommodation' ? 'Min Sleeps' : 'Min. Beds'}</span>
                            <SearchFormSelect
                              field={'min_beds'}
                              formikProps={props}
                              options={minBedsOptions}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="advanced-search-checkboxes">
                      <div className="flex-column">Only display properties...</div>
                      {typeOptions?.flatlet ? (
                        <div
                          className="flex-column"
                        >
                          <Field
                            label="with a flatlet"
                            id={'id_flatlet'}
                            component={Check}
                            name={'flatlet'}
                          />
                        </div>
                      ) : null}
                      {typeOptions?.pets_allowed ? (
                        <div
                          className="flex-column"
                        >
                          <Field
                            label="where pets are allowed"
                            id={'id_pets_allowed'}
                            component={Check}
                            name={'pets_allowed'}
                          />
                        </div>
                      ) : null}
                      {typeOptions?.furnished ? (
                        <div
                          className="flex-column"
                        >
                          <Field
                            label="that are furnished"
                            id={'id_furnished'}
                            component={Check}
                            name={'furnished'}
                          />
                        </div>
                      ) : null}
                      {typeOptions?.on_show ? (
                        <div
                          className="flex-column"
                        >
                          <Field
                            label="that are on show"
                            id={'id_on_show'}
                            component={Check}
                            name={'on_show'}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </AnimateHeight>
              ) : null}
              {(search_secondary && !getIn(window, 'request.is_mobile')) ?
                <MiniSearchPortal
                  propertyCount={propertyCount}
                  selectedPropertyTypes={selectedPropertyTypes}
                  container={search_secondary as HTMLElement}
                />
                : null}

              <div className="advanced-search-bottom">
                {(!advanced && !getIn(window, 'request.is_mobile')) ?
                  <div>
                    <Button onClick={setWebRefSearchActive}>
                      {webRefSearchActive ? <RefreshIcon /> : <SharpIcon />}
                      {webRefSearchActive ? 'Advanced Search' : 'Web Ref Number Search'}
                    </Button>
                  </div>
                  : null}

                {(!webRefSearchActive && !getIn(window, 'request.is_mobile')) ? (
                  <div className="advanced-search-buttons">
                    <div>
                      <b className="property-counter">{propertyCount} properties</b>
                    </div>

                    <Button
                      onClick={() => setAdvanced(!advanced)}>
                      <MoreSearchOptionsIcon upsideDown={advanced} />
                      {!advanced ? 'More Search Options' : 'Less Search Options'}
                    </Button>

                    <Button
                      variant={propertyCount ? 'primary' : 'disabled'}
                      onClick={() => {
                        if (propertyCount) {
                          props.handleSubmit()
                        }
                      }}>
                      <SearchIcon />
                    SEARCH
                    </Button>
                  </div>
                ) : null}
                {getIn(window, 'request.is_mobile') ? (
                  <div className="advanced-search-buttons">
                    {getIn(window, 'request.path') === '/property-search/' ? (
                      <div>
                        <b className="property-counter">{propertyCount} properties</b>
                        <Button
                          onClick={() => setAdvanced(!advanced)}>
                          <MoreSearchOptionsIcon upsideDown={advanced} />
                          {!advanced ? 'More Search Options' : 'Less Search Options'}
                        </Button>
                      </div>
                    ) : (
                      <Button
                        onClick={() => {
                          (window as Window).location.href = '/property-search/'
                        }}>
                        <MoreSearchOptionsIcon upsideDown={advanced} />
                      ADVANCED OPTIONS
                      </Button>
                    )}

                    <Button
                      variant={propertyCount ? 'primary' : 'disabled'}
                      onClick={() => {
                        if (propertyCount) {
                          props.handleSubmit()
                        }
                      }}>
                      <SearchIcon />
                    SEARCH
                    </Button>
                  </div>
                ) : null}
              </div>
              {getIn(window, 'request.path') === '/property-search/' ? (
                <div className="advanced-search-webref-fields">
                  <Field
                    type="text"
                    name="web_ref_search"
                    placeholder="Web Reference Number e.g. RL123"
                    className="input-field"
                  />

                  <Button
                    variant={web_ref_search ? 'primary' : 'disabled'}
                    onClick={() => {
                      webRefSearchSubmit({ web_ref_search: props.values.web_ref_search })
                    }}>
                    <SearchIcon />
                  SEARCH
                  </Button>
                </div>
              ) : null}
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
